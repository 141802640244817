import { PageProps } from 'gatsby'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import ContactContainer from '../../components/ContactContainer'
import ContactDescription from '../../components/ContactDescription'
import ContactFormContainer from '../../components/ContactFormContainer'
import Form from '../../components/Form'
import LargeTabs from '../../components/LargeTabs'
import contactTabs from '../../data/contactTabs'
import { ContactFormData, ContactType } from '../../models/ContactFormData'
import { Tab } from '../../models/Tab'
import { yupResolver } from '@hookform/resolvers/yup'
import contactFormSchema from '../../validations/contactFormSchema'
import Modal from '../../components/Modal'
import Button from '../../components/Button'
import { LayoutProps } from '../../components/Layout.context'
import { useModal } from '../../components/ReactModal'
import fetchSaveApi from '../../utils/fetchSaveApi'
import { ContactRequest } from '../../models/ContactRequest'
import useFormError from '../../hooks/useFormError'
import { Helmet } from 'react-helmet'

const ContactPage: React.FC<PageProps> = () => {
  const { isOpen, requestOpen, onRequestClose } = useModal()
  const [currentTab, setCurrentTab] = useState<Tab<ContactType>>(contactTabs[0])
  const showNewsletter = useMemo(
    () => currentTab.path === 'client',
    [currentTab]
  )
  const showFiles = useMemo(
    () => currentTab.path === 'recrutement',
    [currentTab]
  )
  const showOptions = useMemo(
    () => showNewsletter || showFiles,
    [showNewsletter, showFiles]
  )

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
    clearErrors,
  } = useForm<ContactFormData>({
    resolver: yupResolver(contactFormSchema),
    shouldUnregister: true,
    defaultValues: {
      type: currentTab.path,
    },
  })

  useEffect(() => {
    setValue('type', currentTab.path)
    clearErrors()
  }, [currentTab])

  const [formError, setFormError] = useFormError(isSubmitting)

  const onSubmit = handleSubmit(async (data) => {
    const req: ContactRequest = {
      lastname: data.lastname,
      firstname: data.firstname,
      email: data.email,
      phone: data.phone,
      subject: data.subject,
      message: data.message,
      newsletter: data.newsletter,
      file1: data.files && data.files.length > 0 ? data.files[0] : undefined,
      file2: data.files && data.files.length > 1 ? data.files[1] : undefined,
    }

    const formData = new FormData()
    for (const [key, value] of Object.entries(req)) {
      if (typeof value === 'boolean') {
        formData.append(key, value ? 'true' : 'false')
      } else if (value instanceof File) {
        formData.append(key, value, value.name)
      } else if (typeof value === 'string') {
        formData.append(key, value)
      }
    }

    const resp = await fetchSaveApi<string>(
      `/website/v1/contact/${currentTab.path}`,
      {
        method: 'POST',
        body: formData,
        headers: {
          'Content-Type': '', // used to remove default Content-Type
        },
      }
    )

    if (!resp) {
      setFormError({
        type: 'required',
        message: "Une erreur est survenue lors de l'envoi du message",
      })
      return
    }

    requestOpen()
    return
  })

  return (
    <>
      <Helmet>
        <meta
          name="description"
          title="Contactez nos équipes d'experts agréés !"
          content="Save, leader de la réparation avec près de 200 magasins en France. Save est partenaire avec les grands constructeurs comme Samsung, Apple, Huawei et Xiaomi !"
        />
      </Helmet>

      <ContactContainer>
        <LargeTabs
          items={contactTabs}
          currentPath={currentTab.path}
          onChange={setCurrentTab}
        />

        <ContactDescription>
          Que vous soyez un client, un journaliste, un candidat ou que
          souhaitiez rejoindre notre réseau de magasins Save, n'hésite pas à
          nous contacter, notre Service Client vous répondra dans les plus brefs
          délais !
        </ContactDescription>

        <Form onSubmit={onSubmit}>
          <ContactFormContainer>
            <Form.Input
              {...register('lastname')}
              error={errors.lastname}
              placeholder="Nom"
            />
            <Form.Input
              {...register('firstname')}
              error={errors.firstname}
              placeholder="Prénom"
            />
            <Form.Input
              {...register('email')}
              error={errors.email}
              placeholder="Adresse mail"
            />
            <Form.Input
              {...register('phone')}
              error={errors.phone}
              placeholder="Numéro"
            />
            <Form.Input
              {...register('subject')}
              error={errors.subject}
              placeholder="Objet"
            />
            <Form.Textarea
              {...register('message')}
              error={errors.message}
              placeholder="Message…"
              minRows={9}
            />
            {showOptions && (
              <Form.Options>
                {showNewsletter && (
                  <Form.Option
                    {...register('newsletter')}
                    error={errors.newsletter}
                    label="Je souhaites recevoir les promotions de Save"
                  />
                )}
                {showFiles && (
                  <Form.Files
                    {...register('files')}
                    error={errors.files}
                    accept="application/pdf"
                    label={
                      <>
                        Ajouter votre Cv et votre lettre de motivation
                        <br />
                        (uniquement en .pdf)
                      </>
                    }
                  />
                )}
              </Form.Options>
            )}
            <Form.Submit
              disabled={isSubmitting}
              size="large"
              error={formError}
            />
          </ContactFormContainer>
        </Form>
        <Modal
          showHero
          isOpen={isOpen}
          onRequestClose={onRequestClose}
          message={
            <>
              Votre message nous <br />a bien été envoyé
            </>
          }
        >
          <Button.Link to="/">Retour à la page d’accueil</Button.Link>
        </Modal>
      </ContactContainer>
    </>
  )
}

const layoutProps: LayoutProps = {
  title: 'Contactez-nous ou retrouvez-nous sur les réseaux sociaux',
  breadcrumb: [{ label: 'Nous Contacter' }],
  showHighlights: true,
}

export default Object.assign(ContactPage, {
  layoutProps,
})
