import React from 'react'
import styled, { css } from 'styled-components'
import { Icon, Tab } from '../models/Tab'
import AspectRatio from './AspectRatio'

const square = css`
  width: 100%;
  min-width: 95px;
  max-width: 95px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    max-width: 165px;
  }
`

const Container = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 11px;
`

const Item = styled.li`
  flex: 1;
  ${square}
`

interface TabLabelProps {
  active?: boolean
}

const TabLabel = styled.span<TabLabelProps>`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  cursor: pointer;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: ${({ theme }) => theme.colors.body};
  padding: 10px;
  position: relative;
  transition: all 300ms ease;

  & > img {
    background-color: red;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  }

  ${({ active }) => {
    return (
      active &&
      css`
        background-color: ${({ theme }) => theme.colors.primary};
        color: #fff;
      `
    )
  }}
`

const AspectRatioWrapper = styled(AspectRatio)`
  ${square}
`

const formatSvgViewBox = ({ x, y, w, h }: Icon) => `${x} ${y} ${w} ${h}`

const IconWrapper = styled.span`
  display: none;
  align-items: center;
  justify-content: center;
  width: 61px;
  height: 51px;
  margin-bottom: 10px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: inline-flex;
  }
`

interface LabelProps {
  withIcon?: boolean
}

const Label = styled.span<LabelProps>`
  ${({ withIcon }) => {
    if (withIcon) {
      return css`
        @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
          min-height: 28px;
        }
      `
    } else {
      return css`
        @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
          font-size: 16px;
          line-height: 19px;
        }
      `
    }
  }}
`

interface LargeTabsProps<T extends string = string> {
  items: Tab<T>[]
  currentPath: string
  onChange?: (tab: Tab<T>) => void
  routed?: boolean
}

const LargeTabs = <T extends string = string>({
  items,
  currentPath,
  onChange,
}: LargeTabsProps<T>): JSX.Element => (
  <Container>
    {items.map((item, index) => (
      <Item key={index}>
        <AspectRatioWrapper
          mobile={{ width: 165, height: 163 }}
          desktop={{ width: 165, height: 163 }}
        >
          <TabLabel
            active={item.path === currentPath}
            onClick={() => onChange && onChange(item)}
          >
            {item.icon && (
              <IconWrapper>
                <svg
                  width={item.icon.w}
                  height={item.icon.h}
                  viewBox={formatSvgViewBox(item.icon)}
                >
                  <use xlinkHref={`${item.icon.path}#icon`} />
                </svg>
              </IconWrapper>
            )}

            <Label withIcon={!!item.icon}>{item.label}</Label>
          </TabLabel>
        </AspectRatioWrapper>
      </Item>
    ))}
  </Container>
)

export default LargeTabs
