import { ContactType } from '../models/ContactFormData'
import { Tab } from '../models/Tab'

const contactTabs: Tab<ContactType>[] = [
  {
    label: 'Média',
    path: 'journaliste',
    icon: {
      path: '/images/contact/Media.svg',
      x: 0,
      y: 0,
      w: 48,
      h: 42,
    },
  },
  {
    label: 'Votre avis nous intéresse',
    path: 'client',
    icon: {
      path: '/images/contact/Avis.svg',
      x: 0,
      y: 0,
      w: 28,
      h: 48,
    },
  },
  {
    label: 'Rejoignez-notre réseau de franchisé',
    path: 'franchise',
    icon: {
      path: '/images/contact/Franchise.svg',
      x: 0,
      y: 0,
      w: 61,
      h: 46,
    },
  },
  {
    label: 'Rejoignez-nous',
    path: 'recrutement',
    icon: {
      path: '/images/contact/Join.svg',
      x: 0,
      y: 0,
      w: 47,
      h: 51,
    },
  },
  {
    label: 'Autres',
    path: 'other',
    icon: {
      path: '/images/contact/Other.svg',
      x: 0,
      y: 0,
      w: 35,
      h: 35,
    },
  },
]

export default contactTabs
